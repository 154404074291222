import React, { Suspense, Fragment, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HeaderLayout from './Layout/HeaderLayout';
import SidebarLayout from './Layout/SidebarLayout';
import Authenticated from './Guard/Authenticated';
import Guest from './Guard/Guest';
import BaseLayout from './Layout/BaseLayout';
import ServiceGuard from './Guard/ServiceGuard';
import AdminGuard from './Guard/AdminGuard';
import ClientGuard from './Guard/ClientGuard';
import Adminaccess from './Guard/Adminaccess';
import LoadScreen from './Component/loaderScreen';
import NormalGuard from './Guard/NormalGuard';


export function RenderRout() {
 
  return (
    <>
      <Router>
        <Suspense fallback={<LoadScreen/>}
        >
          <Routes>
            {routes?.map((route, i) => {
              const Guard = route?.guard || Fragment;
              const Auth = route?.auth || Fragment;
              const Layout = route?.layout || Fragment;
              const Component = route?.element;
              return (
                <Route
                  key={i}
                  path={route.path}
                  exact={route.exact}
                  element={
                    <Guard>
                      <Auth>
                        <Layout >
                          <Component />
                        </Layout>
                      </Auth>
                    </Guard>
                  }
                />
              );
            })}
          </Routes>
        </Suspense>
      </Router>
    </>
  )

}

const routes = [

  // AUth 
  {
    guard: Guest,
    layout: BaseLayout,
    exact: true,
    path: '/get-started',
    element: lazy(() => import('./Component/Auth/Index'))
  },

  {
    guard: Guest,
    layout: BaseLayout,
    exact: true,
    path: '/login/:type',
    element: lazy(() => import('./Component/Auth/AuthLogin/Login'))
  },

  {
    guard: Guest,
    layout:BaseLayout,
    exact: true,
    path: '/emailverify/:id/:type',
    element: lazy(() => import('./Component/Auth/AuthLogin/Verfiy_email'))
  },

  {
    guard: Guest,
    layout: BaseLayout,
    exact: true,
    path: '/forget-password/:type',
    element: lazy(() => import('./Component/Auth/AuthForgetPassword/ForgetPassword'))
  },

  {
    guard: Adminaccess,
    layout: HeaderLayout,
    exact: true,
    path: '/service-provider-register',
    element: lazy(() => import('./Component/Auth/ServiceProvider/Register'))
  },
  {
    guard: Adminaccess,
    layout: BaseLayout,
    exact: true,
    path: '/client-register',
    element: lazy(() => import('./Component/Auth/Client/Index'))
  },
  //Home page 
  {
    guard:NormalGuard,
    layout: HeaderLayout,
    exact: true,
    path: '/',
    element: lazy(() => import('./Component/HeaderComponent/Homepage/Home'))
  },
  {
    guard:NormalGuard,
    layout: HeaderLayout,
    exact: true,
    path: 'cms-page/:slug',
    element: lazy(() => import('./Component/Pages/CmsPages/Index'))
  },
  {
    guard:NormalGuard,
    layout: HeaderLayout,
    exact: true,
    path: '/about-us',
    element: lazy(() => import('./Component/Pages/CmsPages/AboutUs'))
  },
  {
    guard:NormalGuard,
    layout: HeaderLayout,
    exact: true,
    path: '/contact-us',
    element: lazy(() => import('./Component/Pages/CmsPages/ContactUs'))
  },

  {
    guard:NormalGuard,
    layout: HeaderLayout,
    exact: true,
    path: '/faqs',
    element: lazy(() => import('./Component/HeaderComponent/Homepage/FAQs/FAQ'))

  },
  {
    guard:NormalGuard,
    layout: HeaderLayout,
    exact: true,
    path: '/category',
    element: lazy(() => import('./Component/HeaderComponent/Homepage/Category'))

  },
  {
    layout: SidebarLayout,
    guard: Authenticated,
    exact: true,
    path: '/notification',
    element: lazy(() => import('./Component/HeaderComponent/Notification/Index'))

  },

  //Dashboard Pages
  {
    guard: Authenticated,
    layout: SidebarLayout,
    exact: true,
    path: '/dashboard',
    element: lazy(() => import('./Component/Pages/Dasboards/Index'))

  },
  //Profile Pages
  {
    guard: Authenticated,
    layout: SidebarLayout,
    exact: true,
    path: '/profile',
    element: lazy(() => import('./Component/Pages/Profile/Index'))

  },
  {
    guard: Authenticated,
    layout: SidebarLayout,
    exact: true,
    path: '/update-password',
    element: lazy(() => import('./Component/Auth/UpdatePassword'))

  },
  {
    guard: Authenticated,
    layout:SidebarLayout,
    exact: true,
    path: '/meetings',
    element: lazy(() => import('./Component/Pages/Client/Meetings/Index'))
    
  },
  {
    guard: Authenticated,
    layout:SidebarLayout,
    exact: true,
    path: '/messages',
    element: lazy(() => import('./Component/Pages/Client/Messages/Index'))
    
  }, 
  // {
  //   guard: Authenticated,
  //   layout: SidebarLayout,
  //   exact: true,
  //   path: '/meetings',
  //   element: lazy(() => import('./Component/Pages/Client/Meetings/Index'))

  // },

  {
    guard: Authenticated,
    layout:SidebarLayout,
    exact: true,
    path: '/messages/chat/:chatId',
    element: lazy(() => import('./Component/Pages/Client//Messages/Chat'))
    
  },

  {
    guard: Authenticated,
    layout:SidebarLayout,
    exact: true,
    path: '/awarded-jobs/hours-logged/:id/:clientId',
    element: lazy(() => import('./Component/Pages/ServiceProvider/Awarded/LoggedHours'))
  },

  {
    guard: Authenticated,
    auth: AdminGuard,
    layout: SidebarLayout,
    exact: true,
    path: '/profile/:id/:type',
    element: lazy(() => import('./Component/Pages/Profile/Index'))
  },



  // {
  //   guard: Authenticated,
  //   auth: ServiceGuard,
  //   layout: SidebarLayout,
  //   exact: true,
  //   path: '/saved-job',
  //   element: lazy(() => import('./Component/Pages/Savejobs/index'))

  // },
  //worker
  
  {
    guard: Authenticated,
    auth: ServiceGuard,
    layout: SidebarLayout,
    exact: true,
    path: '/worker',
    element: lazy(() => import('./Component/Pages/ServiceProvider/Worker/Index'))

  },

  {
    guard: Authenticated,
    auth: ServiceGuard,
    layout: HeaderLayout,
    exact: true,
    path: '/add-worker',
    element: lazy(() => import('./Component/Pages/ServiceProvider/Worker/AddWorker/Index'))

  },

  {
    guard: Authenticated,
    auth: ServiceGuard,
    layout: SidebarLayout,
    exact: true,
    path: '/worker/edit-worker/:id',
    element: lazy(() => import('./Component/Pages/ServiceProvider/Worker/EditWorker/Index'))
  },
  {
    guard: Authenticated,
    layout: HeaderLayout,
    exact: true,
    path: '/job-list',
    element: lazy(() => import('./Component/Pages/ServiceProvider/FindJobs/Index'))
  },
  {
    guard: Authenticated,
    layout: HeaderLayout,
    exact: true,
    path: '/job-list/:id',
    element: lazy(() => import('./Component/Pages/ServiceProvider/FindJobs/Index'))
  },

  {
    guard: Authenticated,
    auth: ClientGuard,
    layout: HeaderLayout,
    exact: true,
    path: '/supplier-details/:id/:Jobid',
    element: lazy(() => import('./Component/Pages/Client/Jobs/SupplierDetails/Index'))

  },
  //Client
  {
    guard: Authenticated,
    layout: SidebarLayout,
    exact: true,
    path: '/jobs',
    element: lazy(() => import('./Component/Pages/Client/Jobs/Index'))

  },

  {
    guard: Authenticated,
    layout: HeaderLayout,
    exact: true,
    path: '/jobs-view/:id',
    element: lazy(() => import('./Component/Pages/Client/Jobs/JobView/Index'))

  },
  {
    guard: Authenticated,
    auth: ServiceGuard,
    layout: HeaderLayout,
    exact: true,
    path: '/invoice/:jobId/:clientId/:supplierId',
    element: lazy(() => import('./Component/Pages/Invoice/Index'))

  },
  {
    guard: Authenticated,
    auth: ServiceGuard,
    layout: HeaderLayout,
    exact: true,
    path: '/invoice/:jobId/:clientId/:supplierId/:invoiceId',
    element: lazy(() => import('./Component/Pages/Invoice/Index'))

  },
  {
    guard: Authenticated,
    layout: HeaderLayout,
    exact: true,
    path: '/invoice-view/:jobId/:clientId/:supplierId/:invoiceId',
    element: lazy(() => import('./Component/Pages/Invoice/ViewInvoice'))

  },
  {
    guard: Authenticated,
    layout: HeaderLayout,
    exact: true,
    path: '/auto-invoice/:jobId/:clientId/:supplierId/:invoiceId',
    element: lazy(() => import('./Component/Pages/Invoice/AutomaticInvoice/Index'))

  },
  {
    guard: Authenticated,
    layout: SidebarLayout,
    exact: true,
    path: '/invoice/list',
    element: lazy(() => import('./Component/Pages/Invoice/InvoiceList'))

  },
  {
    guard: Authenticated,
    auth: ClientGuard,
    layout: HeaderLayout,
    exact: true,
    path: '/jobs-post',
    element: lazy(() => import('./Component/Pages/Client/Jobs/JobPost/Index'))

  },
  {
    guard: Authenticated,
    auth: ClientGuard,
    layout: HeaderLayout,
    exact: true,
    path: '/jobs-edit/:id',
    element: lazy(() => import('./Component/Pages/Client/Jobs/JobEdit/Index'))

  },

  {
    guard: Authenticated,
    auth: ClientGuard,
    layout: SidebarLayout,
    exact: true,
    path: '/apply-job/:id',
    element: lazy(() => import('./Component/Pages/Client/Jobs/AppliedJob/Index'))

  },

  {
    guard: Authenticated,
    auth: ServiceGuard,
    layout: SidebarLayout,
    exact: true,
    path: '/applied-jobs',
    element: lazy(() => import('./Component/Pages/ServiceProvider/ApplliedJob/Index'))
  },
  {
    guard: Authenticated,
    auth: ServiceGuard,
    layout: SidebarLayout,
    exact: true,
    path: '/saved-jobs',
    element: lazy(() => import('./Component/Pages/ServiceProvider/SaveJob/Index'))
  },
  {
    guard: Authenticated,
    layout: SidebarLayout,
    exact: true,
    path: '/awarded-jobs',
    element: lazy(() => import('./Component/Pages/ServiceProvider/Awarded/Index'))
  },
  {
    guard: Authenticated,
    layout:SidebarLayout,
    exact: true,
    path: '/completed-jobs',
    element: lazy(() => import('./Component/Pages/ServiceProvider/CompletedJobs/Index'))
  },
   
  {
    guard: Authenticated,
    auth: ServiceGuard,
    layout: SidebarLayout,
    exact: true,
    path: '/relevant-jobs',
    element: lazy(() => import('./Component/Pages/ServiceProvider/RelevantJobs/Index'))
  },

  //Admin Panel

  {
    guard: Authenticated,
    auth: AdminGuard,
    layout: SidebarLayout,
    exact: true,
    path: '/add-Service',
    element: lazy(() => import('./Component/Pages/Admin/Services/Index'))

  },
  //working on it
  {
    guard: Authenticated,
    auth: AdminGuard,
    layout: SidebarLayout,
    exact: true,
    path: '/add-tools/:id',
    element: lazy(() => import('./Component/Pages/Admin/Tools/Index'))

  },


  ///404 Page

  {
    layout: BaseLayout,
    exact: true,
    path: '*',
    element: lazy(() => import('./Component/PageNotFound'))

  },


  {
    guard: Authenticated,
    auth: AdminGuard,
    layout: SidebarLayout,
    exact: true,
    path: '/add-language',
    element: lazy(() => import('./Component/Pages/Admin/Language/Index'))
  },
  {
    guard: Authenticated,
    auth: AdminGuard,
    layout: SidebarLayout,
    exact: true,
    path: '/add-logo',
    element: lazy(() => import('./Component/Pages/Admin/Logo/LogoList'))
  },
  {
    guard: Authenticated,
    auth: AdminGuard,
    layout: SidebarLayout,
    exact: true,
    path: '/client',
    element: lazy(() => import('./Component/Pages/Admin/Clients/Index'))
  },
  {
    guard: Authenticated,
    auth: AdminGuard,
    layout: SidebarLayout,
    exact: true,
    path: '/supplier',
    element: lazy(() => import('./Component/Pages/Admin/Supplier/Index'))
  },
  {
    guard: Authenticated,
    auth: AdminGuard,
    layout: SidebarLayout,
    exact: true,
    path: '/testimonial',
    element: lazy(() => import('./Component/Pages/Admin/Testimonial/Index'))
  },
  {
    guard: Authenticated,
    auth: AdminGuard,
    layout: SidebarLayout,
    exact: true,
    path: '/cms',
    element: lazy(() => import('./Component/Pages/Admin/CMS/Index'))
  },
  {
    guard: Authenticated,
    auth: AdminGuard,
    layout: SidebarLayout,
    exact: true,
    path: '/faq',
    element: lazy(() => import('./Component/Pages/Admin/FAQ/Index'))
  },

  {
    guard: Authenticated,
    auth: AdminGuard,
    layout: SidebarLayout,
    exact: true,
    path: '/shift',
    element: lazy(() => import('./Component/Pages/Admin/Shift/Index'))
  },
  {
    guard: Authenticated,
    auth: AdminGuard,
    layout: SidebarLayout,
    exact: true,
    path: '/admin/meetings',
    element: lazy(() => import('./Component/Pages/Admin/Meetings/index'))
  },
  {
    guard: Authenticated,
    auth: AdminGuard,
    layout: SidebarLayout,
    exact: true,
    path: '/admin/message',
    element: lazy(() => import('./Component/Pages/Admin/Messages/index'))
  },
  {
    guard: Authenticated,
    auth: AdminGuard,
    layout: SidebarLayout,
    exact: true,
    path: '/admin/awarded-jobs',
    element: lazy(() => import('./Component/Pages/Admin/AwardedJobs/Index'))
  },
  {
    guard: Authenticated,
    auth: AdminGuard,
    layout: SidebarLayout,
    exact: true,
    path: '/admin/feedback',
    element: lazy(() => import('./Component/Pages/Admin/Feedback/Index'))

  },
  {
    guard: Authenticated,
    auth: AdminGuard,
    layout: SidebarLayout,
    exact: true,
    path: '/admin/skilllist',
    element: lazy(() => import('./Component/Pages/Admin/Skill/Index'))
  },
]
export const defaultConfig = {
    // baseAPIUrl:"http://192.168.0.3:3000/api/v1/", 
    // imagePath:"http://192.168.0.3:3000/",

    //Live Url
    baseAPIUrl:"https://michelle.etrueconcept.com/api/v1/", 
    imagePath:"https://michelle.etrueconcept.com", 
    };

export const type = {
    admin:"admin",
    client:"client",
    supplier:"supplier",
    worker:"worker"
}

export const socialLinks = {
    facebook:"https://www.facebook.com/profile.php?id=61556143662093",
    instagram:"https://www.instagram.com/gomichelle.de",
    linkedin:"https://www.linkedin.com/company/gomichelle/about/?viewAsMember=true"
}


export const contactDetails ={
    phone:" +49 (0) 2161 90 29 558",
    email:"match@gomichelle.de",
    instagram:"gomichelle.de"
}

 //export const termAndCondition = "65bccc2547b0e284c425a2ac";

// for live
 export const termAndCondition = "65c22721d65555e6b9be4b1b";
































































    
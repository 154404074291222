import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { type } from '../config';

export default function SideBarLink({ href, name, icon,handleclick }) {


  return (
    <>
      <NavLink to={href} className={({ isActive, isPending }) =>
        isPending ? "" : isActive ? "active" : ""
      } onClick={handleclick}>
        <span>
          {icon}
        </span>
        {name}
      </NavLink>
    </>
  )
}

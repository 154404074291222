import './App.css';
import { RenderRout } from './router';
import WithGoogleTranslate from './GoogleTranslate';


function App() {
  
 


  return (
    <>
    {/* <WithGoogleTranslate/> */}
   
      <RenderRout />
    </>
  );
}

export default App;

import React, { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PostImage } from '../utils/apiCall';
import { LinearProgress } from '@mui/material';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { TablePagination } from '@mui/material';
import { defaultConfig, termAndCondition } from '../config';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete'
import DatePicker, { registerLocale } from "react-datepicker";
import { de } from 'date-fns/locale/de';
import "react-datepicker/dist/react-datepicker.css";
registerLocale('de', de)

const PrefixUrl = (window.location.pathname.slice(1).split('/')[1] === 'test') ? '/dashboard/test' : '/dashboard';

const Pagination = ({ paginatedItems, rowsPerPage, page, handleChangePage, handleChangeRowsPerPage }) => {
  return (
    <>
      <div className="d-flex align-items-center justify-content-between bottom_nav">
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <TablePagination
              rowsPerPageOptions={[5, 10, 15, 25, 100]}
              component="div"
              count={paginatedItems}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </ul>
        </nav>
      </div>
    </>

  );
}

const Delete = ({ open, handleClose, handleDelete, title, id, loder }) => {
  const { t } = useTranslation();
  return (<>
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={'xs'}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >

      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body logout-popup text-center">
            <span className="mx-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={48}
                height={48}
                viewBox="0 0 48 48"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M21.592 4.4881C25.306 3.6521 28 6.8441 28 10.0001V38.0001C28 41.1561 25.306 44.3481 21.592 43.5121C12.668 41.5041 6 33.5321 6 24.0001C6 14.4681 12.668 6.4961 21.592 4.4881ZM32.586 16.5861C32.9611 16.2112 33.4697 16.0005 34 16.0005C34.5303 16.0005 35.0389 16.2112 35.414 16.5861L41.414 22.5861C41.7889 22.9612 41.9996 23.4698 41.9996 24.0001C41.9996 24.5304 41.7889 25.039 41.414 25.4141L35.414 31.4141C35.0368 31.7784 34.5316 31.98 34.0072 31.9754C33.4828 31.9709 32.9812 31.7606 32.6104 31.3897C32.2395 31.0189 32.0292 30.5173 32.0247 29.9929C32.0201 29.4685 32.2217 28.9633 32.586 28.5861L35.172 26.0001H18C17.4696 26.0001 16.9609 25.7894 16.5858 25.4143C16.2107 25.0392 16 24.5305 16 24.0001C16 23.4697 16.2107 22.961 16.5858 22.5859C16.9609 22.2108 17.4696 22.0001 18 22.0001H35.172L32.586 19.4141C32.2111 19.039 32.0004 18.5304 32.0004 18.0001C32.0004 17.4698 32.2111 16.9612 32.586 16.5861Z"
                  fill="white"
                />
              </svg>
            </span>
            <h3> {t("Delete")}</h3>
            <p>{t("Delete_text")} {title && CapitalizeFirstLatter(title)}?</p>
            <div className="save_btn btnmodled">
              <button
                type="button"
                className="dashboard_btn_outline me-3"
                onClick={handleClose}
              >
                {t("cancel")}
              </button>
              <button type="button" className="dashboard_btn" disabled={loder} onClick={() => {
                handleDelete(id)
                handleClose()
              }}>
                {t("Delete")}
              </button>
            </div>
          </div>
        </div>
      </div>


    </Dialog>
  </>)
}


function CapitalizeLetter(string) {
  return string?.toUpperCase();
}

function debounce(method, pageNo, rowsPerPage, searchQuery, filterQuery) {
  const timer = setTimeout(() => {
    method(pageNo, rowsPerPage, searchQuery, filterQuery)
  }, 500)
  return () => {
    clearTimeout(timer)
  }
}

const ApiLoder = () => {
  return (
    <>
      <div className='spinner_overlay'></div>
      <div className="spinner-box">
        <img src='/image/loder.gif' alt='' className="loading-icon" />
      </div>
    </>

  );
}

export const trimText = (value, size) => {
  let str = ""
  if (value && value.length > size) {
    if (value[size - 1] == " ") str = value.slice(0, size - 1) + "..."
    else str = value.slice(0, size) + "..."

  }
  return str
}


const SeeMore = ({ value, size }) => {
  const [show, setShow] = useState(false)
  const { t } = useTranslation()
  return (
    value?.length > size ?
      <>{show ? value : trimText(value, size)}
        <span className='seeMore' onClick={() => {
          setShow(!show)
        }}>{show ? " " + t("seeLess") : " " + t("seeMore")}</span>
      </>
      : value
  )
}

const SuccessMessage = (message) => {
  setTimeout(() => toast.success(message, {
    position: "top-center",
    autoClose: 2000,
    theme: "colored",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  }), 10)
}

const ErrorMessage = (message) => {
  toast.error(message, {
    position: "top-center",
    autoClose: 2000,
    theme: "colored",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

const CommonUploadImage = async (e, handleonchange, allowedFileTypes) => {
  let file = (e?.target?.files[0])
  let name = e?.target?.name;
  handleonchange((extra) => ({ ...extra, [name]: 'progress' }))


  console.log("dssfsd", allowedFileTypes)
  console.log("file?.name", file?.name)

  if ((allowedFileTypes.includes(file?.type) || allowedFileTypes.includes(file?.name?.split('.').reverse()[0]))) {
    const path = await PostImage(file);
    if (path?.length > 0) {
      handleonchange((extra) => ({ ...extra, [name]: path[0] }))
    }
    else {
      handleonchange((extra) => ({ ...extra, [name]: '' }))
    }
    e.target.value = null
  } else {
    ErrorMessage("Invalid file Formate")
    handleonchange((extra) => ({ ...extra, [name]: '' }))
    e.target.value = null
    return false
  }
}

const ShowPreviewImage = ({ uploadFile, handleonchange, file_name }) => {
  const title = trimText(uploadFile, 10)

  let type = fileType.includes(uploadFile?.split('.').reverse()[0])


  return (
    <>
      {uploadFile === "progress" ? (
        <LinearProgress />
      ) : (
        ""
      )}


      {uploadFile && type &&
        uploadFile !== "progress" ? (
        <div className="col-lg-12 d-flex">

          <p className="image_msg">
            <span
              onClick={() => {
                removeImage(file_name, handleonchange);
              }}
            >
              X
            </span>
            <div style={{ cursor: "pointer" }} onClick={() => { window.open(defaultConfig.imagePath + uploadFile) }}>
              <i className="icofont-file-alt"></i>
              <label style={{ cursor: "pointer" }}>{title}</label>
            </div>
          </p>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

const removeImage = async (name, handleonchange) => {
  handleonchange((val) => ({ ...val, [name]: '' }));
}

const acceptImageType = 'image/png,image/PNG,image/jpg,image/JPG,image/jpeg,image/JPEG'
const imageType = ["image/png", "image/jpg", "image/jpeg", 'JPG', 'PNG', 'JPEG']

const acceptDocOrPdfType = '.doc,.pdf'
const docOrPdfType = ["doc", "pdf"]

const acceptFileType = "image/png,image/PNG,image/jpg,image/JPG,image/jpeg,image/JPEG,.doc, .docx,.xls,.xlsx,.pdf,.csv,"
const fileType = ["image/png", "image/jpg", "image/jpeg", "doc", "docx", "xls", "xlsx", "pdf", "csv", 'jpg', 'png', 'jpeg', 'JPG', 'PNG', 'JPEG']

const acceptVideoType = 'mp4'
const videoType = ["mp4"]


const TermAndCondition = ({ open, onClose, content }) => {
  const contentList = useSelector((store) => store.CommonInfo.commonInfo.cmsList)
  const conditons = contentList.find((item) => { return item?._id == termAndCondition })
  console.log(conditons?.description)

  function createMarkup() {
    return { __html: conditons?.description };
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>

      <div className="modal-header">
        <DialogTitle>{conditons?.title}</DialogTitle>
        <button
          type="button"
          className=" mx-2 btn-close"
          onClick={onClose}

        />
      </div>

      <DialogContent>
        <DialogContentText>
          <div className="secb_div">
            <p className="mb-2" dangerouslySetInnerHTML={createMarkup()} />
          </div>


        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function CapitalizeFirstLatter(s) {
  return s[0].toUpperCase() + s.slice(1);
}

const HandleDelete = ({ isOpen, handleClose, handleDelete, deleteTitle, loder }) => {

  const { t } = useTranslation();
  return (
    <>
      <Dialog
        open={isOpen}
        fullWidth={true}
        maxWidth={'xs'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body logout-popup text-center">
              <span className="mx-auto">

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={48}
                  height={48}
                  viewBox="0 0 48 48"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.592 4.4881C25.306 3.6521 28 6.8441 28 10.0001V38.0001C28 41.1561 25.306 44.3481 21.592 43.5121C12.668 41.5041 6 33.5321 6 24.0001C6 14.4681 12.668 6.4961 21.592 4.4881ZM32.586 16.5861C32.9611 16.2112 33.4697 16.0005 34 16.0005C34.5303 16.0005 35.0389 16.2112 35.414 16.5861L41.414 22.5861C41.7889 22.9612 41.9996 23.4698 41.9996 24.0001C41.9996 24.5304 41.7889 25.039 41.414 25.4141L35.414 31.4141C35.0368 31.7784 34.5316 31.98 34.0072 31.9754C33.4828 31.9709 32.9812 31.7606 32.6104 31.3897C32.2395 31.0189 32.0292 30.5173 32.0247 29.9929C32.0201 29.4685 32.2217 28.9633 32.586 28.5861L35.172 26.0001H18C17.4696 26.0001 16.9609 25.7894 16.5858 25.4143C16.2107 25.0392 16 24.5305 16 24.0001C16 23.4697 16.2107 22.961 16.5858 22.5859C16.9609 22.2108 17.4696 22.0001 18 22.0001H35.172L32.586 19.4141C32.2111 19.039 32.0004 18.5304 32.0004 18.0001C32.0004 17.4698 32.2111 16.9612 32.586 16.5861Z"
                    fill="white"
                  />
                </svg>

              </span>
              <h3>{t("Delete")}</h3>
              <p>{deleteTitle} ?</p>
              <div className="save_btn btnmodled">
                <button
                  type="button"
                  className="dashboard_btn_outline me-3"
                  onClick={handleClose}
                >
                  {t("cancel")}
                </button>
                <button type="button" className="dashboard_btn" disabled={loder}
                  onClick={handleDelete}
                >
                  {t("Delete")}
                </button>
              </div>
            </div>
          </div>
        </div>


      </Dialog>
    </>
  )
}

const GetPrivewFileType = ({ file }) => {
  const type = file?.name?.split('.').reverse()[0]
  if (type === 'pdf') {
    return (
      <Link to={defaultConfig.imagePath + file} className="mb-0" download target="_blank">
        <img src="/image/pdf.png" className="img-fluid" alt="" />
      </Link>
    )
  } else {
    return (
      <Link to={defaultConfig.imagePath + file} className="mb-0" download target="_blank">
        <img src="/image/doc.png" className="img-fluid" alt="" />
      </Link>
    )
  }
}

function cleanObject(obj) {
  for (const key in obj) {
    if (obj[key] === '') {
      delete obj[key]; // Remove empty string
    } else if (Array.isArray(obj[key]) && obj[key].length === 0) {
      delete obj[key]; // Remove empty array
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      cleanObject(obj[key]); // Recursively clean nested objects
      if (Object.keys(obj[key]).length === 0) {
        delete obj[key]; // Remove empty object
      }
    }
  }
  return obj;
}



function SubmitButton({ onClick, disabled, text }) {
  return (
    <button disabled={disabled} type="button" onClick={onClick} className='dashboard_btn'>
      {text}
      {disabled && <i class="fa fa-spinner fa-spin" style={{ marginLeft: '10px' }}></i>}
    </button>
  );
}

const FetchAddress = ({ RegisterFormData, handleonchange, name }) => {

  const handleChangemap = (address) => {
    handleonchange((val) => { return { ...val, [name]: address } })
  };

  const handleSelect = async (address) => {
    const results = await geocodeByAddress(address);
    handleonchange((val) => { return { ...val, [name]: results[0].formatted_address } })
  };

  return (
    <PlacesAutocomplete
      value={RegisterFormData?.[name]}
      name={name}
      onChange={handleChangemap}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <input {...getInputProps({ placeholder: '' })} className="form-control" />
          <div>
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion) => {
              const style = {
                backgroundColor: suggestion.active ? '#41b6e6' : '#fff',
              };
              return (
                <div {...getSuggestionItemProps(suggestion, { style })}>
                  {suggestion.description}
                </div>
              );
            })}
          </div>
        </div>
      )}

    </PlacesAutocomplete>
  )
}

const PickDate = ({ setValue, name, value = "" }) => {
  const { t } = useTranslation()
  return (
    <DatePicker
      className="form-control input-custom"
      locale="de"
      placeholderText={`${t("select")} datum`}
      selected={value}
      onChange={(date) => setValue((val) => { return { ...val, [name]: (date).toString() } })}
    />
  )
}

const PutCross = (props) => {
  const { removeImage } = props
  return (
    <div>
      <span
        style={{ fontWeight: "bold", cursor: "pointer" }}
        onClick={() => {
          removeImage();
        }}
      >
        X
      </span>
      {props.children}
    </div>
  )
}

const BackButton = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  return (
<div className='d-block  mobile-back p-0'>
<Link onClick={()=>{
  navigate(-1)
}}>
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
    <g clipPath="url(#clip0_582_3222)">
      <path d="M21.0833 10.0833H6.79636L11.6482 5.23153C12.0061 4.87356 12.0061 4.29314 11.6482 3.93517C11.2902 3.57719 10.7098 3.57719 10.3518 3.93517L3.93516 10.3518C3.9138 10.3732 3.8936 10.3956 3.87444 10.419C3.86576 10.4295 3.8582 10.4407 3.85003 10.4516C3.84024 10.4647 3.83014 10.4774 3.82107 10.491C3.81235 10.504 3.80479 10.5175 3.79684 10.5309C3.78949 10.5432 3.78176 10.5552 3.77497 10.5679C3.76771 10.5815 3.76152 10.5955 3.75499 10.6094C3.74876 10.6227 3.74214 10.6357 3.73651 10.6492C3.73093 10.6627 3.72641 10.6765 3.72152 10.6902C3.71627 10.7047 3.71073 10.719 3.70622 10.7339C3.70209 10.7476 3.69904 10.7615 3.69556 10.7753C3.69174 10.7904 3.68748 10.8054 3.68443 10.8209C3.68125 10.8368 3.67941 10.853 3.67709 10.8691C3.67515 10.8826 3.67257 10.8958 3.67124 10.9095C3.66832 10.9393 3.66673 10.9692 3.66673 10.999C3.66673 10.9993 3.66669 10.9997 3.66669 11C3.66669 11.0003 3.66673 11.0006 3.66673 11.0009C3.66677 11.0308 3.66832 11.0606 3.67124 11.0904C3.67257 11.1041 3.67515 11.1173 3.67709 11.1308C3.67941 11.147 3.68125 11.1631 3.68443 11.1791C3.68748 11.1945 3.69174 11.2095 3.69556 11.2246C3.69904 11.2385 3.70205 11.2524 3.70622 11.266C3.71073 11.2809 3.71627 11.2952 3.72152 11.3098C3.72641 11.3234 3.73093 11.3372 3.73651 11.3507C3.74214 11.3643 3.74871 11.3773 3.75499 11.3905C3.76152 11.4044 3.76771 11.4184 3.77497 11.432C3.78176 11.4447 3.78949 11.4567 3.79684 11.469C3.80483 11.4824 3.81235 11.4959 3.82107 11.5089C3.83014 11.5225 3.84028 11.5353 3.85003 11.5483C3.8582 11.5592 3.86572 11.5704 3.87444 11.5809C3.8936 11.6043 3.9138 11.6268 3.93516 11.6481L10.3518 18.0648C10.7098 18.4227 11.2902 18.4227 11.6482 18.0648C12.0061 17.7068 12.0061 17.1264 11.6482 16.7684L6.79636 11.9167H21.0833C21.5896 11.9167 22 11.5063 22 11C22 10.4937 21.5896 10.0833 21.0833 10.0833Z" fill="black" />
    </g>
    <defs>
      <clipPath id="clip0_582_3222">
        <rect width="22" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>
  {t("back")}</Link>
</div>
  )
}

const ConfirmationPopup = ({
  open,
  confirmation,
  setConfirmation,
  popupHeading,
  text,
  handleClose,
}) => {
  const {t} = useTranslation()
  return (<>
    <Dialog
      open={open}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "666px",
            borderRadius: "30px"
            // Set your width here
          },
        },
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >

      <div className="modal-dialog filter_modal">
        <div className="modal-content">
          <div className='model-header-csutm position-relative'>
            {popupHeading}
            <i class="icofont-close"
              onClick={() => {
                handleClose()
              }}
            ></i>

          </div>

          <div className="row mt-1">

            <div className="col-lg-12 mb-lg-4 mb-md-3 my-3">
              <p>{text}</p>
            </div>

            <div className="d-flex align-items-center justify-content-end gap-3 mt-lg-4 mt-md-4 mt-3">
              <button className="dashboard_btn_outline" disabled={confirmation} type='button'
                onClick={() => {
                  handleClose();
                }}
              >
                {t("cancel")}
              </button>

              <SubmitButton
                text={t('confirm')}
                onClick={() => {
                  setConfirmation(true)
                  handleClose();
                }}
                disabled={confirmation}
              />
            </div>
          </div>
        </div>
      </div>


    </Dialog>
  </>)
}

export { BackButton,ConfirmationPopup, PutCross, PickDate, ApiLoder, FetchAddress, PrefixUrl, debounce, SeeMore, Delete, ErrorMessage, SuccessMessage, CapitalizeLetter, CommonUploadImage, removeImage, ShowPreviewImage, acceptImageType, imageType, acceptFileType, fileType, acceptVideoType, videoType, acceptDocOrPdfType, docOrPdfType, TermAndCondition, CapitalizeFirstLatter, Pagination, HandleDelete, GetPrivewFileType, cleanObject, SubmitButton }




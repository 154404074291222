import {useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { type } from '../config';
import { Pathname } from '../Redux/Redux/Action/Action';
import { useDispatch } from 'react-redux';

const Adminaccess = ({ children }) => {
  const dispatch=useDispatch();
  const location = useLocation();
  dispatch(Pathname(location.pathname))
    const token = localStorage.getItem('accessToken')
  const navigate = useNavigate();
  const user=JSON.parse(localStorage.getItem('userData'));
 useEffect(() => {
  if (token) {
    if (user?.userType === type.admin) {
   }
   else{
    navigate('/dashboard')
   }
 }
}, [token,navigate]);




  return <>
  {children}</>;
};
export default Adminaccess;
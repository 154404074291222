import { Actiontypes } from "./ActionType";

  export const Profile = (data) => {
    return {
        type: Actiontypes.PROFILE,
         payload: data,
    };
  };  

  export const ServiceProvider = (data) => {
    return {
        type: Actiontypes.REGISTER,
         payload: data,
    };
  }; 
  
  export const AddWorkerAction = (data) => {
    return {
        type: Actiontypes.ADDWORKER,
         payload: data,
    };
  }; 

  export const EditWorkerAction = (data) => {
    return {
        type: Actiontypes.EDITWORKER,
         payload: data,
    };
  }; 

  export const NotificationAction = (data) => {
    return {
        type: Actiontypes.NOTIFICATION,
         payload: data,
    };
  }; 

  export const CommonInfo = (data) => {
    return {
      type:Actiontypes.COMMONINFO,
      payload:data
    }
  }

  export const Pathname = (data) => {
    return {
      type:Actiontypes.PATHNAME,
      payload:data
    }
  }

  

 
  
  
  
  
  
  
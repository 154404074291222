import axios from 'axios';
 import { removeAuth } from '../helpers/auth';
import { defaultConfig } from '../config';
import { ErrorMessage } from '../helpers/common';

const axiosInt = axios.create({ 
    baseURL: defaultConfig.baseAPIUrl
}
);

axiosInt.interceptors.response.use(
  (response) => {
   
    if(response.data.message === "Ihr Konto ist nicht genehmigt. Bitte warten Sie auf die Genehmigung."){
      ErrorMessage(response.data.message);
      const timeoutId = setTimeout(() => {
        window.location.href = '/profile';
      }, 1000);
      // Cleanup function to clear the timeout if the component unmounts
      return () => clearTimeout(timeoutId);
    
    }
    return response;
  },
  (error) =>{
   if(error?.response?.status === 401){
   removeAuth();  
   window.location.href = '/';
   }
    // Promise.reject(
    //   (error?.response && error?.response?.data) || 'There is an error!'
    // )
  }
   
);

export default axiosInt;

export const apiUrls = {
    //image upload
    uploadFiles: 'fileUpload',
    // dashboard
    dashbaords: 'admin/dashbaords',
    dashbaord: 'admin/dashbaord',
    // home
    homeSlider: 'home',
    detailsCms: 'admin/detailsCms',
    sendInquiry:"sendInquiry",
    // Notification
    notificationList: 'admin/notificationList',
    notificationCount:"admin/notificationCount",
    notificationView:"admin/notificationView",
    //Auth
    login: 'login', 
    signUp: 'signUp',
    forgetPassword:'forgetPassword',
    emailverify:'emailverify',
    verifyOtp:'verifyOtp',
    updatePassword:"updatePassword",
    userDetails:'userDetails',
    clientSupplierDetails:'admin/clientSupplierDetails',
    updateProfile:'updateProfile',
    supplierUpdate:'admin/supplierUpdate',
    clientUpdate:'admin/clientUpdate',
    resetPassword:"/changePassword",

     //Common Api
     serviceList: 'admin/serviceLists',
     toolList: 'admin/toolLists',
     contentLists:'contentLists',
     skillList:'admin/skillLists',
     logout:'admin/logout',
    //Worker
    addWorker: 'addWorker',
    workerDetail: 'admin/worker-detail',
    workerLists:'admin/workerLists',
    workerDelete:'admin/workerDelete',
    updateWorker:'admin/updateWorker',
    markWorkerBusyFree:"admin/markWorkerBusyFree",
    //job post
    joblist:'admin/jobLists',
    appliedJob:'admin/appliedJob',
    saveJobLists:'admin/saveJobLists',
    statusUpdateJob:'admin/statusUpdateJob',
    applyJob:'admin/applyJob',
    saveJob:'admin/saveJob',
    logawardapproveReject:"admin/approveReject",
    markCompleted:"admin/markCompleted",
    meeting:"admin/meetings",
    reject:"admin/rejectSupplier",
    rejectMeeting:"admin/actionMeeting",
    rescheduleMeeting:"admin/reschduleMeeting",
    award:"admin/awardJob",
    awardJobList:"admin/awardedJobList",
    workingHour:"admin/addWorkingLog",
    workingHourList:"admin/workingLogList",
    addFeedback:"admin/addFeedback",
    completedJobList:"admin/completedJobList",

    //chat
    chat:"admin/chat",
    chatList:"admin/chatListing",
    chatDetail:"admin/chatDetails",

    //Invoice
    projectDetails:"admin/invoice",
    submitInvoice:"admin/submit/invoice",
    invoiceDetails:"admin/invoice/details",
    editInvoice:"admin/updateInvoice",
    invoicelists:"admin/invoice/lists",
    updatePaymentStatus:"admin/updatePaymentStatus",
    invoiceMessage:"admin/invoiceMessage",
    invoiceDownload:"admin/invoiceDownload",
    invoicelistsadmin:"admin/invoice/lists/admin",

    //AWARD JOB
  schduleMeeting:'admin/schduleMeeting',

    //Admin API
    //services
    AddService:'admin/addService',
    ServiceListAdmin:'admin/serviceList',
    EditService:'admin/updateService',
    DeleteService:'admin/deleteService',
    adminstatusUpdate:'admin/statusUpdate',
   

  // Jobs
  addJob:'admin/addJob',
  JobDetail:'admin/job-details',
  updateJob:'admin/updateJob',
  jobDetails:'admin/jobDetails',
  supplierDetails:'admin/supplierDetails',
  appliedSupplier:'admin/appliedSuppliers',
  deleteJob:'admin/deleteJob',

  releventJobs:"admin/releventJobs",

  //Tools
  toolListAdmin:'admin/toolList',
  addTool:'admin/addTool',
  editTool:'admin/updateTool',
  deleteTool:'admin/deleteTool',

  //Language
  languageList:'admin/languageList',
  addLanguage:'admin/addLanguage',
  updateLanguage:'admin/updateLanguage',
  deleteLanguage:'admin/deleteLanguage',

  //Logo
  logoList:'admin/LogoLists',
  addLogo:'admin/addLogo',
  editLogo:'admin/updateLogo',
  deleteLogo:'admin/deleteLogo',

  //client
  clientList:'admin/clientList',
  deleteClient:'',

  //supplier
  supplierList:'admin/supplierList',
  supplierKeyPerson:'admin/supplierKeyPerson',

  //testimonial
  testimonialList:'admin/testimonialLists',
  addTestimonial:'admin/addTestimonial',
  editTestimonial:'admin/updateTestimonial',
  deleteTestimonial:'admin/deleteTestimonial',

  //CMS
  cmsList:'admin/cmsList',
  addCMS:'admin/addCms',
  updateCMS:'admin/updateCms',
  deleteCMS:'admin/deleteCms',
  
  //FAQ
  faqList:'admin/faqList',
  addFaq:'admin/addFaq',
  updateFaq:'admin/updateFaq',
  deleteFaq:'admin/deleteFaq',
  
  //Shift
  shiftList:'admin/shiftList',
  shiftUpdate:'admin/shiftUpdate',
  addShift:'admin/addShift',
  
  //meetings
  meetingsForAdmin:'admin/meetingsForAdmin',
  approveRejectByAdmin:'admin/approveRejectByAdmin',
  awardedJobListForadmin:'admin/awardedJobListForadmin',
  
  // Approve message
  adminChatListings:'admin/chatListings',
  adminApproveChat:'admin/approveChat',
  adminChatBlock:'admin/blockChat',

  //feedback
  feedbackList:'admin/feedbacks',
  feedbackStatus:'admin/approveFeedback',
  
 //skill
 adminskillList:"admin/skillList",
 addSkill:"admin/addSkill",
 updateSkill:"admin/updateSkill",
 deleteSkill:"admin/deleteSkill",
};

import {useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { type } from '../config';
import { Pathname } from '../Redux/Redux/Action/Action';
import { useDispatch } from 'react-redux';

const ServiceGuard = ({ children }) => {
  const dispatch=useDispatch();
  const location = useLocation();
  dispatch(Pathname(location.pathname))
    const user=JSON.parse(localStorage.getItem('userData'));
  const navigate = useNavigate();
  
 useEffect(() => {
  if (user?.userType !== type.supplier) {
    navigate('/dashboard')
 }
}, [user]);



  return <>
  {children}</>;
};
export default ServiceGuard;
